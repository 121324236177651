// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---templates-explore-gradients-js": () => import("../templates/explore/gradients.js" /* webpackChunkName: "component---templates-explore-gradients-js" */),
  "component---templates-explore-box-shadows-js": () => import("../templates/explore/box-shadows.js" /* webpackChunkName: "component---templates-explore-box-shadows-js" */),
  "component---templates-explore-type-scale-js": () => import("../templates/explore/type-scale.js" /* webpackChunkName: "component---templates-explore-type-scale-js" */),
  "component---pages-404-js": () => import("../pages/404.js" /* webpackChunkName: "component---pages-404-js" */),
  "component---pages-animation-color-pulse-js": () => import("../pages/animation-color-pulse.js" /* webpackChunkName: "component---pages-animation-color-pulse-js" */),
  "component---pages-avatars-js": () => import("../pages/avatars.js" /* webpackChunkName: "component---pages-avatars-js" */),
  "component---pages-block-js": () => import("../pages/block.js" /* webpackChunkName: "component---pages-block-js" */),
  "component---pages-bootstrap-colors-js": () => import("../pages/bootstrap-colors.js" /* webpackChunkName: "component---pages-bootstrap-colors-js" */),
  "component---pages-box-shadows-js": () => import("../pages/box-shadows.js" /* webpackChunkName: "component---pages-box-shadows-js" */),
  "component---pages-buttons-js": () => import("../pages/buttons.js" /* webpackChunkName: "component---pages-buttons-js" */),
  "component---pages-color-scale-js": () => import("../pages/color-scale.js" /* webpackChunkName: "component---pages-color-scale-js" */),
  "component---pages-color-js": () => import("../pages/color.js" /* webpackChunkName: "component---pages-color-js" */),
  "component---pages-color-saved-js": () => import("../pages/color/saved.js" /* webpackChunkName: "component---pages-color-saved-js" */),
  "component---pages-color-share-js": () => import("../pages/color/share.js" /* webpackChunkName: "component---pages-color-share-js" */),
  "component---pages-colors-js": () => import("../pages/colors.js" /* webpackChunkName: "component---pages-colors-js" */),
  "component---pages-conic-gradients-js": () => import("../pages/conic-gradients.js" /* webpackChunkName: "component---pages-conic-gradients-js" */),
  "component---pages-delaunay-js": () => import("../pages/delaunay.js" /* webpackChunkName: "component---pages-delaunay-js" */),
  "component---pages-explore-type-scales-js": () => import("../pages/explore/type-scales.js" /* webpackChunkName: "component---pages-explore-type-scales-js" */),
  "component---pages-font-pairings-js": () => import("../pages/font-pairings.js" /* webpackChunkName: "component---pages-font-pairings-js" */),
  "component---pages-gradients-js": () => import("../pages/gradients.js" /* webpackChunkName: "component---pages-gradients-js" */),
  "component---pages-gradients-votes-js": () => import("../pages/gradients/votes.js" /* webpackChunkName: "component---pages-gradients-votes-js" */),
  "component---pages-gradients-votes-share-js": () => import("../pages/gradients/votes/share.js" /* webpackChunkName: "component---pages-gradients-votes-share-js" */),
  "component---pages-images-js": () => import("../pages/images.js" /* webpackChunkName: "component---pages-images-js" */),
  "component---pages-index-js": () => import("../pages/index.js" /* webpackChunkName: "component---pages-index-js" */),
  "component---pages-logo-js": () => import("../pages/logo.js" /* webpackChunkName: "component---pages-logo-js" */),
  "component---pages-page-section-js": () => import("../pages/page-section.js" /* webpackChunkName: "component---pages-page-section-js" */),
  "component---pages-shadow-tint-scale-js": () => import("../pages/shadow-tint-scale.js" /* webpackChunkName: "component---pages-shadow-tint-scale-js" */),
  "component---pages-stripes-js": () => import("../pages/stripes.js" /* webpackChunkName: "component---pages-stripes-js" */),
  "component---pages-tachyons-theme-js": () => import("../pages/tachyons-theme.js" /* webpackChunkName: "component---pages-tachyons-theme-js" */),
  "component---pages-text-gradients-js": () => import("../pages/text-gradients.js" /* webpackChunkName: "component---pages-text-gradients-js" */),
  "component---pages-text-images-js": () => import("../pages/text-images.js" /* webpackChunkName: "component---pages-text-images-js" */),
  "component---pages-text-shadows-js": () => import("../pages/text-shadows.js" /* webpackChunkName: "component---pages-text-shadows-js" */),
  "component---pages-text-stripes-js": () => import("../pages/text-stripes.js" /* webpackChunkName: "component---pages-text-stripes-js" */),
  "component---pages-texts-js": () => import("../pages/texts.js" /* webpackChunkName: "component---pages-texts-js" */),
  "component---pages-theme-ui-js": () => import("../pages/theme-ui.js" /* webpackChunkName: "component---pages-theme-ui-js" */),
  "component---pages-voronoi-js": () => import("../pages/voronoi.js" /* webpackChunkName: "component---pages-voronoi-js" */),
  "component---pages-about-mdx": () => import("../pages/about.mdx" /* webpackChunkName: "component---pages-about-mdx" */),
  "component---pages-changelog-mdx": () => import("../pages/changelog.mdx" /* webpackChunkName: "component---pages-changelog-mdx" */),
  "component---pages-docs-box-shadow-mdx": () => import("../pages/docs/box-shadow.mdx" /* webpackChunkName: "component---pages-docs-box-shadow-mdx" */),
  "component---pages-docs-colors-mdx": () => import("../pages/docs/colors.mdx" /* webpackChunkName: "component---pages-docs-colors-mdx" */),
  "component---pages-docs-theme-mdx": () => import("../pages/docs/theme.mdx" /* webpackChunkName: "component---pages-docs-theme-mdx" */),
  "component---pages-docs-typography-font-sizes-mdx": () => import("../pages/docs/typography/font-sizes.mdx" /* webpackChunkName: "component---pages-docs-typography-font-sizes-mdx" */),
  "component---pages-docs-typography-google-font-pairing-mdx": () => import("../pages/docs/typography/google-font-pairing.mdx" /* webpackChunkName: "component---pages-docs-typography-google-font-pairing-mdx" */),
  "component---pages-index-old-mdx": () => import("../pages/index-old.mdx" /* webpackChunkName: "component---pages-index-old-mdx" */),
  "component---pages-docs-typography-index-mdx": () => import("../pages/docs/typography/index.mdx" /* webpackChunkName: "component---pages-docs-typography-index-mdx" */),
  "component---pages-marketing-mdx": () => import("../pages/marketing.mdx" /* webpackChunkName: "component---pages-marketing-mdx" */),
  "component---pages-notes-index-mdx": () => import("../pages/notes/index.mdx" /* webpackChunkName: "component---pages-notes-index-mdx" */),
  "component---pages-notes-logo-mdx": () => import("../pages/notes/logo.mdx" /* webpackChunkName: "component---pages-notes-logo-mdx" */),
  "component---pages-privacy-mdx": () => import("../pages/privacy.mdx" /* webpackChunkName: "component---pages-privacy-mdx" */),
  "component---pages-serendipity-mdx": () => import("../pages/serendipity.mdx" /* webpackChunkName: "component---pages-serendipity-mdx" */),
  "component---pages-style-api-mdx": () => import("../pages/style-api.mdx" /* webpackChunkName: "component---pages-style-api-mdx" */),
  "component---pages-terms-mdx": () => import("../pages/terms.mdx" /* webpackChunkName: "component---pages-terms-mdx" */),
  "component---pages-theme-font-sizes-mdx": () => import("../pages/theme/font-sizes.mdx" /* webpackChunkName: "component---pages-theme-font-sizes-mdx" */)
}

